<template>
  <b-modal
    ref="infoModal"
    hide-footer
    :title="
      !getLoggedInUser
        ? get(school, 'is_chat_enabled')
          ? 'Chat With Us'
          : 'Request Information Form'
        : 'Program Inquiry Form'
    "
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
    class="bg-light"
  >
    <p class="text-muted" style="" v-if="!getLoggedInUser">
      Complete the form below and we’ll send you upcoming classes and additional info about this program.
    </p>
    <b-form @submit.prevent="onSubmit" id="demo-form">
      <b-form-group label="Program" label-for="program" label-class="form-label" v-if="getLoggedInUser">
        <v-select
          id="program"
          class="form-control v-select-custom"
          label="title"
          v-model="selectedProgram"
          :reduce="(program) => program.id"
          placeholder="Select Program"
          :options="allPrograms"
          :loading="areProgramsLoading"
          @input="fetchScholarships(selectedProgram)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!selectedProgram" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.image" class="img-fluid" width="20" alt="Logo" v-if="option.image" />
                <md-icon v-else>local_library</md-icon>
              </fmv-avatar>
              <span>{{ option.title }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.title }}
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Inquiry Type" label-for="inquiry" label-class="form-label">
        <v-select
          id="inquiry"
          class="form-control v-select-custom"
          label="text"
          v-model="selectedType"
          :reduce="(type) => type.value"
          placeholder="Select inquiry type"
          :options="selectedInquiryOptions"
          @input="fetchScholarships()"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="selectedType === 'scholarship' && scholarshipsData.length"
        label="Sponsorships"
        label-for="scholarships"
        label-class="form-label"
      >
        <v-select
          id="scholarships"
          class="form-control v-select-custom"
          label="title"
          v-model="selectedScholarships"
          placeholder="Select sponsorships"
          :options="scholarshipsData"
          multiple
        >
        </v-select>
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model.trim="firstName" required />
        </b-form-group>

        <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model.trim="lastName" required />
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label" class="col-md-6">
          <b-form-input
            id="email"
            :placeholder="$t('authMsgs.emailAddr')"
            v-model="email"
            type="email"
            autocomplete="off"
            required
            :state="errorStates.userEmail"
          />
          <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label" class="col-md-6">
          <b-form-input
            id="phone"
            placeholder="(888) 689 - 1235"
            v-model="phone"
            v-mask="'(###) ### - ####'"
            :state="errorStates.phone"
            required
          />
          <b-form-invalid-feedback>
            Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group>
        <textarea
          class="form-control"
          rows="5"
          placeholder="Write a message to send."
          v-model="message"
          required
        ></textarea>
      </b-form-group>
      <b-form-group
        label="Permission to text?"
        label-for="permission_to_text"
        label-class="form-label"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="permission_to_text"
          v-model="permission_to_text"
          :options="yesNoOptions"
          :aria-describedby="ariaDescribedby"
          required
          :state="errorStates.permission_to_text"
        ></b-form-radio-group>
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
      <div>
        <re-captcha @getToken="getToken" class=""> </re-captcha>
        <span v-if="!isTokenAvailable" class="text-danger">Please fill recaptcha before submit.</span>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <b-btn variant="primary" :disabled="isLoading" type="submit" style="width: 150px" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Submit Request</span>
        </b-btn>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { PHONE_FORMAT_REGEX } from '../../../../common/constants';
import { titleize } from '../../../../common/utils';
import ReCaptcha from './ReCaptcha.vue';
import vSelect from 'vue-select';
import { FmvAvatar } from 'fmv-avatar';
export default {
  name: 'InfoModal',
  components: { ReCaptcha, vSelect, FmvAvatar },
  props: {
    showModal: { type: Boolean, default: false },
    program: { type: Object, default: null },
    scholarships: { type: Array, default: () => [] },
    types: { type: String, default: 'program' },
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
      permission_to_text: null,
      errorStates: {
        userEmail: null,
        phone: null,
        permission_to_text: null,
      },
      yesNoOptions: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
      selectedInquiryOptions: [
        { value: 'program', text: 'General' },
        { value: 'scholarship', text: 'Sponsorship' },
      ],
      allPrograms: [],
      isLoading: false,
      school: null,
      selectedProgram: null,
      selectedType: null,
      selectedScholarships: [],
      areProgramsLoading: false,
      scholarshipsData: [],
      captchaToken: '',
      isTokenAvailable: true,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.resetData();
        this.selectedType = this.types;
        if (this.scholarships.length) {
          this.scholarshipsData = this.scholarships;
        }
        this.$refs.infoModal.show();

        if (this.getLoggedInUser) {
          this.selectedProgram = this.program.id;
          this.fetchPrograms();
        }
      }
    },

    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.school = value;
        }
      },
    },
  },
  methods: {
    ...mapActions('programInfo', ['createProgramInfo']),
    ...mapActions('program', ['getAllPrograms', 'getAllScholarshipList']),
    get,
    getToken(token) {
      this.captchaToken = token;
      this.isTokenAvailable = true;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },
    async fetchScholarships(program = null) {
      try {
        const response = await this.getAllScholarshipList({
          program_id: program || this.program.id,
          is_active: true,
        });

        this.scholarshipsData = response.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async onSubmit() {
      this.isLoading = true;

      if (this.captchaToken) {
        this.isTokenAvailable = true;

        let config = {
          headers: {
            'X-Recaptcha-Token': this.captchaToken,
          },
        };

        if (!PHONE_FORMAT_REGEX.test(this.phone)) {
          this.errorStates.phone = false;
          this.isLoading = false;
          return;
        }
        const data = {
          email_message: this.email,
          first_name: titleize(this.firstName),
          last_name: titleize(this.lastName),
          email: this.email,
          phone: this.phone,
          types: this.selectedType,
          is_text_allowed: this.permission_to_text,
          program: this.program.id ? this.program.id : this.selectedProgram,
          school: this.getLoggedInUser ? this.getLoggedInUser.linked_entity.id : this.school.id,
          comments: this.message,
          extra: { scholarships: this.selectedScholarships },
        };
        try {
          const res = await this.createProgramInfo({ data, config });

          this.makeToast({ variant: 'success', msg: !this.getLoggedInUser ? 'Request Sent!' : 'Inquiry Added' });
          this.hideModalManual();

          if (get(this.school, 'is_chat_enabled') && !this.getLoggedInUser) {
            // TODO: Convert to subdomain flow.
            setTimeout(() => {
              window.open(`${process.env.VUE_APP_CHAT_BOT_SUBDOMAIN}/livechat/${res.data.data.chat_id}`);
            });
          }
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.isTokenAvailable = false;
      }
      this.isLoading = false;
    },
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.infoModal.hide();
      this.hideModal();
    },

    resetData() {
      this.firstName = this.lastName = this.email = this.phone = this.message = '';
      this.selectedProgram = null;
      this.selectedType = null;
      this.selectedScholarships = [];
      this.permission_to_text =
        this.errorStates.userEmail =
        this.errorStates.phone =
        this.errorStates.permission_to_text =
          null;
    },
  },

  async mounted() {},
};
</script>
